@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");
@import './fonts.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

html{
    overflow-x: hidden;
    /* overflow-y: hidden; */
}

.persian-indigo-bg {
    background-color: #140042;
}

.font-poppins-semibold {
    font-family: Poppins, sans-serif;
    font-weight: 600;
}