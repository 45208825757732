.hideMenuNav {
  display: none;
}

.showMenuNav {
  display: flex-column;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}


.fixed-navbar{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  transition: all 0.4s ease;
  height: 5.5rem;
}

.edc-logo{
  z-index: 9999;
}

.nav-item{
  color: white;
  font-size: 1rem;
  margin: 0 0.5rem;
  transition: all 0.2s ease-in;
  cursor: pointer;
  padding: 0.7rem;
  border-radius: 0.5rem;
}

.setNavBackgroundDark{
  height: 5rem;
  background-color: #140042;
}

.nav-item:hover{
  color: #4FC8EF;
  background-color:rgb(35, 15, 65);
}


.nav-register-now{
  color: blueviolet;
  border: 2px solid blueviolet;
  position: relative;
  top: 0.5rem;
  padding: 0.6rem;
  border-radius: 0.5rem;
  transition: all 0.4s ease;
}

.nav-register-now:hover{
  color: white;
  background-color: blueviolet;
}

/* Mobile Nav */

