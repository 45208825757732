.timerComponentWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0 0.5rem 0;
}


.timerWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 22rem;
    /* border: 2px solid saddlebrown; */
    box-sizing: border-box;
    padding: 0 2rem;
    color: white;
    size: 1.2rem;
    font-weight: 500;
}

.timerCountdownSection{
    z-index: 999;
    display: flex;
    justify-content: space-around;
    margin: 0.4rem 0;
}

.timerColon{
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 1rem;
}

.timerCountdownSectionElement{
    width: 4rem;
    height: 5rem;
    display: flex;
    flex-direction: column;
}

.timerCountdownValue{
    display: flex;
    justify-content: space-around;
}

.sectionGrid{
    background-color: rgb(0, 0, 105);
    border-radius: 0.2rem;
    height: 3rem;
    width: 1.5rem;
    display: flex;
    font-size: 2rem;
    align-items: center;
    justify-content: center;
    font-family: "FjallaOne";
}

.timerCountdownUnit{
    font-size: 0.8rem;
    margin: 0.2rem;
}