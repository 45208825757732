.heroContainer{
  background-color: #0f0031;
  height: 100vh;
}


.video-container {
    position: absolute;
    top: 0rem;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -0.8;
    overflow: hidden;
}

.video-container video {
  opacity: 0.4;
  width: 100vw;
  height: 100vh;
  position: absolute;
  object-fit: cover;
  overflow: hidden;
}

.scroll-below{
  filter: invert(100%);
  width: 3.5rem;
  height: 4rem;
  margin: 1rem 0;
  animation: scroll-bounce 1s infinite;
  cursor: pointer;
}

@keyframes scroll-bounce {
  0%{
    transform: translateY(0.1rem);
  }

  50%{
    transform: translateY(-0.1rem);
  }

  100%{
    transform: translateY(0.1rem);
  }
}

