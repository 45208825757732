.modal-component-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(189, 189, 189, 0.9);
    z-index: 99999;
}

.modal-container {
    /* border: 2px solid blue; */
    width: 35vw;
    margin: auto;
    color: white;
    font-family: 'Times New Roman', Times, serif;
    border-radius: 2rem;
    background: rgba(2, 0, 36, 0.8);
    font-size: 1.1rem;
    background: linear-gradient(331deg, rgba(2, 0, 36, 1) 10%, rgba(8, 8, 112, 1) 75%, rgba(9, 9, 121, 1) 85%, rgba(0, 212, 255, 1) 97%, rgba(0, 212, 255, 1) 99%);
    position: fixed;
    left: 33vw;
    top: 10rem;
    z-index: 999991;
}

@media only screen and (max-width: 1000px) {
    .modal-container {
        position: fixed;
        left: 30vw;
        top: 32rem !important;
    }
}

.modal-topic {
    padding: 2rem;
    text-align: center;
    padding: 1rem 0;
    font-size: 2.5rem;
    font-family: "xirod";
    color: #4FC8EF;
}

.modal-desc {
    padding: 0 3rem 1rem 3rem;
    border-radius: 2rem;
}

.modal-buttons {
    margin: 1rem 0 0 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 1.4rem;
    font-family: 'Times New Roman', Times, serif;
}

.modal-buttons button {
    width: 50%;
    padding: 1rem 0;
}

.modal-register-button {
    border-bottom-right-radius: 2rem;
    background-color: orangered;
}

.modal-register-button:hover {
    background-color: rgb(125, 33, 0);
}

.modal-close-button {
    border-bottom-left-radius: 2rem;
    background-color: rgb(0, 0, 29);
}

.modal-close-button:hover {
    background-color: rgb(29, 29, 29);
}

@media only screen and (max-width: 1024px) {
    .modal-container {
        width: 55vw;
        position: fixed;
        left: 25vw;
    }
}

@media only screen and (max-width: 1024px) {
    .modal-container {
        width: 50vw;
        position: fixed;
        left: 25vw;
    }
}