.Eventcard {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.eve-card {
  margin: 0.5rem 5rem;
}
.workshop-link {
  color: white;
  overflow: visible;
}
.eve-card .circle {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 40px;
  left: 30%;
  text-align: center;
  border: 2px solid #4FC8EF;
  transition: 0.3s all;
  cursor: pointer;
  z-index: 10;
}
.card {
  background: #B49791;
  background: radial-gradient(100% 100% at 28.98% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
  box-shadow: 0px 4px 24px -1px rgb(0 0 0 / 20%);
  backdrop-filter: blur(20px);
  box-shadow: 0px 4px 24px -1px rgb(0 0 0 / 20%);
  backdrop-filter: blur(20px);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.circle .colo {
  color: #4FC8EF;
  font-size: 27px;
  transition: all 0.3s ease-in;
}

.circle:hover{
  transform: translateY(-5px)
}

.workshop-heading {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
}
.eve-card .rec {
  height: 400px;
  max-width: 450px;
  background: radial-gradient(100% 100% at 28.98% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  box-shadow: 0px 4px 24px -1px rgb(0 0 0 / 20%);
  backdrop-filter: blur(20px);
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 35px;
  text-align: justify;
  font-size: 18px;
  text-align: justify;
}
.workshop-desc{
  font-size: medium;
  color: white;
}
.eve-btn {
  padding: 15px 34px;
  border-radius: 15px;
  border: none;
  color: #4FC8EF;
  font-weight: 600;
  font-size: 17px;
  transition: 0.5s all;
  margin-top: 20px;
  transition: all 0.5s ease-in;
}

.eve-btn:hover{
  background-color: rgb(47, 47, 47);
  box-shadow: 0px 4px 24px -5px rgb(0 0 0 / 50%);
  transform: scale(1.01);
}


.workshops-heading{
  width: 30rem;
  margin: auto;
  margin-top: 4rem;
}

.comp-info {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  text-align: center;
  margin: 10px 6%;
  color: #B49791;
}