.parameterValuesContainer{
    background-color: transparent;
    color: white;
    display: flex;
    justify-content: space-between;
    width: 75%;
    margin: 5rem auto;
}

.parameterValuesCircle{
    background: rgb(2,0,36);
background: linear-gradient(115deg, rgba(2,0,36,1) 0%, rgba(8,8,112,1) 61%, rgba(9,9,121,1) 73%, rgba(0,212,255,1) 97%, rgba(0,212,255,1) 100%);
    border-radius: 30rem;
    width: 12rem;
    height: 12rem;
    padding: 3rem 1.5rem;
    border: 5px solid darkblue;
}

.value{
    font-size: 2rem;
    font-weight: bold;
}

.value-desc{
    color: white;
    font-size: 1rem;
    font-family: 'xirod';
}


@media only screen and (max-width: 1024px){
    .parameterValuesCircle{
        width: 10rem;
        height: 10rem;
        padding: 3rem 3rem;
    }
    .value{
        font-size: 1.4rem;
    }
    .value-desc{
        font-size: 0.6rem;
    }
}