/* eve-btn css will be found in Workshop.css */
.competitions-container-heading{
    text-align: center;
    color: white;
    margin: 5rem 0;
    font-size: 3.7rem;
    font-weight: bold;
}

.competitions-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin: 0 3rem;
}

.competitions-card{
    height: 23rem;
    width: 25vw;
    border: 2px solid #4FC8EF;
    margin: 4rem 0 1rem 0;
    backdrop-filter: blur(20px);
    border-radius: 2rem;
    padding: 2.3rem 2rem;
    transition: all 0.2s ease-in;
    background-position: center center;
    background-size: cover;
    background-color: transparent;
}

.competitions-card:hover{
    transform: scale(1.02);
}

.competitions-card-heading{
    color:#4FC8EF;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    font-family: "xirod";
}

.competitions-card-desc{
    color: white;
    text-align: center;
    font-size: 1rem;
    margin-top: 1.5rem;
}

.extended-eve-btn{
    /******extended******/
    margin: auto;
    text-align: center;
    cursor: pointer;
    width: 50%;
    margin-top: 1rem;
}

#cc1::after{
    content: "";
    background: url('../assets/competition-standup.jpeg') no-repeat center center/cover;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    z-index: -1;
    opacity: 0.5;
}

#cc2::after{
    content: "";
    background: url('../assets/competition-barter.PNG') no-repeat center center/cover;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    z-index: -1;
    opacity: 0.5;
}

#cc3::after{
    content: "";
    background: url('../assets/competition-mtm.PNG') no-repeat center center/cover;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    z-index: -1;
    opacity: 0.5;
}

#cc4::after{
    content: "";
    background: url('../assets/competition-stockwars.PNG') no-repeat center center/cover;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    z-index: -1;
    opacity: 0.5;
}

#cc5::after{
    content: "";
    background: url('../assets/competition-uthalputhal.PNG') no-repeat center center/cover;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    z-index: -1;
    opacity: 0.5;
}

@media only screen and (max-width: 1024px){
    .competitions-card{
        width: 70%;
        height: 40vh;
    }

    .competitions-card-heading{
        font-size: 2.9rem;
        margin: 2rem 0;
    }
    
    .competitions-card-desc{
        font-size: 2.2rem;
    }
    .extended-eve-btn{
        width: 70% !important;
    }
}


/* Alternate CSS */

/* @media only screen and (max-width: 1024px){
    .competitions-card{
        width: 70%;
    }

    .competitions-card-desc{
        font-size: 1.2rem;
    }

    .extended-eve-btn{
        font-size: 1.1rem !important;
    }
} */
