/* #sessions {
  color: white;
  padding: 50px 50px;
  position: relative;
  overflow: visible;
  background-color: rgb(0, 0, 0, 0.5);
}
.common {
  position: relative;
  max-width: 1450px;
  margin-left: auto;
  margin-right: auto;
}
#competition{
  text-align: center;
  background-size: contain;
  background-blend-mode: multiply;
  border-radius: 200px;
  width: 100%;
  margin-bottom: 20px;
  margin-left: 0;
  padding: 40px 20px;
}
#competition .heading img {
  width: 100%;
}
.comp {
  display: flex;
  justify-content: center;
  max-width: max-content;
  align-items: stretch;
  margin: 0 auto;
}
.comp-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 50px 35px;
  overflow-x: visible;
}
.session-desc {
  color: white;
  overflow: visible;
}
.comp-card {
  height: max-content;
  width: 20vw;
  max-width: 350px;
  min-width: 250px;
  flex-direction: column;
  border: 2px solid #4FC8EF;
  transition: 0.2s all;
  margin: 20px 0;
  padding: 30px 20px;
  transition: all 0.3s ease-in;
}

.comp-card:hover{
  transform: scale(1.05);
}
.card {
  background: #B49791;
  background: radial-gradient(100% 100% at 28.98% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
  box-shadow: 0px 4px 24px -1px rgb(0 0 0 / 20%);
  backdrop-filter: blur(20px);
  box-shadow: 0px 4px 24px -1px rgb(0 0 0 / 20%);
  backdrop-filter: blur(20px);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.comp-card img {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.comp-card p {
  margin: 20px 0;
  text-align: center;
  font-size: 0.9rem;
}
#competition button {
  position: static;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}
.eve-btn {
  padding: 15px 34px;
  border-radius: 15px;
  border: none;
  color: #4FC8EF;
  font-weight: 600;
  font-size: 15px;
  transition: 0.5s all;
  margin-top: 20px;
}

.session-card-heading{
  font-family: "xirod";
  font-size: 2.1rem;
  color: #4FC8EF;
}

.session-card-topic{
  font-family: "xirod";
  font-size: 1.11rem;
}



.events-heading {
  color: white;
  font-size: 3.7rem;
  font-weight: bolder;
}

@media only screen and (max-width: 1024px){
  .session-card-topic{
    font-size: 0.9rem;
  }
  .session-desc{
    font-size: 0.5rem;
  }
} */



/* Alternate Style (For Responsiveness) */


#sessions {
  color: white;
  padding: 50px 50px;
  position: relative;
  overflow: visible;
  background-color: rgb(0, 0, 0, 0.5);
}
.common {
  position: relative;
  max-width: 1450px;
  margin-left: auto;
  margin-right: auto;
}
#competition{
  text-align: center;
  background-size: contain;
  background-blend-mode: multiply;
  border-radius: 200px;
  width: 100%;
  margin-bottom: 20px;
  margin-left: 0;
  padding: 40px 20px;
}
#competition .heading img {
  width: 100%;
}
.comp {
  display: flex;
  justify-content: center;
  max-width: max-content;
  align-items: stretch;
  margin: 0 auto;
  flex-wrap: wrap;
}
.comp-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 50px 35px;
  overflow-x: visible;
}
.session-desc {
  color: white;
  overflow: visible;
}
.comp-card {
  height: max-content;
  width: 20vw;
  max-width: 350px;
  min-width: 250px;
  flex-direction: column;
  border: 2px solid #4FC8EF;
  transition: 0.2s all;
  margin: 20px 0;
  padding: 30px 20px;
  transition: all 0.3s ease-in;
}

.comp-card:hover{
  transform: scale(1.05);
}
.card {
  background: #B49791;
  background: radial-gradient(100% 100% at 28.98% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
  box-shadow: 0px 4px 24px -1px rgb(0 0 0 / 20%);
  backdrop-filter: blur(20px);
  box-shadow: 0px 4px 24px -1px rgb(0 0 0 / 20%);
  backdrop-filter: blur(20px);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.comp-card img {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.comp-card p {
  margin: 20px 0;
  text-align: center;
  font-size: 0.9rem;
}
#competition button {
  position: static;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}
.eve-btn {
  padding: 15px 34px;
  border-radius: 15px;
  border: none;
  color: #4FC8EF;
  font-weight: 600;
  font-size: 15px;
  transition: 0.5s all;
  margin-top: 20px;
}

.session-card-heading{
  font-family: "xirod";
  font-size: 2.1rem;
  color: #4FC8EF;
}

.session-card-topic{
  font-family: "xirod";
  font-size: 1.11rem;
}


.events-heading {
  color: white;
  font-size: 3.7rem;
  font-weight: bolder;
}

@media only screen and (max-width: 1024px){
  .session-card-topic{
    font-size: 2rem;
  }

  .comp-card p{
    font-size: 1.9rem;
  }
  .comp-row{
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .comp-card{
    height: 40rem;
    max-width: 65vw !important;
    min-width: 250px !important;
    width: 65vw !important;
    margin: 2rem 3rem;
  }

  #session-heading-order{
    order:-1;
    height: 10rem;
  }

  #row-2{
    order: -1;
  }

  .events-heading{
    margin-bottom: 4rem;
  }
  .comp-row{
    margin: 0 35px;
  }
}

